export const emptyTag = {
  TagName: 'New Tag',
  LoggingFrequency: 60,
  DynamicProperties:
    '{"flags":{"High Frequency Logged":false},"isHidden":false,"manualEntry":false,"syncToHub":false}',
};

export const leftColNames = ['TagName', 'TagIdentifier'];

export const rightColNames = ['Delete', 'Duplicate', 'Edit'];

export const leftColumns = [
  { title: 'Tag Name', width: 150, readonly: false, hasMenu: true },
  { title: 'Tag Identifier', width: 150, readonly: false, hasMenu: true },
];

export const rightColumns = [
  { title: 'Delete', width: 75, readonly: true },
  { title: 'Duplicate', width: 100, readonly: true },
  { title: 'Edit', width: 50, readonly: true },
];

export const gridTheme = {
  textHeader: 'white',
  bgHeader: '#017dba',
  accentColor: '#017dba',
  accentFg: 'black',
  bgHeaderHasFocus: '#017dba',
  bgHeaderHovered: 'gray',
};

export const optionalColumns = [
  'DisplayName',
  'UniversalDescriptor',
  'DeviceInfo',
  'ParentTagID',
  'MaxValue',
  'MinValue',
  'Gain',
  'Offset',
  'AlarmLoLo',
  'AlarmLo',
  'AlarmHiHi',
  'AlarmHi',
  'DisplayFormat',
  'UnitOfMeasure',
  'LoggingFrequency',
  'GroupName',
  'IsActive',
  'Description',
  'AssetNumber',
  'IsWriteable',
  'Transform',
  'ScanFrequency',
  'DynamicProperties',
  'SourceType',
  'AlarmType',
  'DataType',
  'ModbusRegister',
  'ModbusIP',
  'ModbusPort',
  'ModbusSlaveId',
];

export const optionalColumnDefs = {
  DisplayName: {
    title: 'Display Name',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  UniversalDescriptor: {
    title: 'Universal Descriptor',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  DeviceInfo: {
    title: 'Device Info',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  ParentTagID: {
    title: 'Parent Tag ID',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  MaxValue: {
    title: 'Max Value',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  MinValue: {
    title: 'Min Value',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  Gain: {
    title: 'Gain',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  Offset: {
    title: 'Offset',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AlarmLoLo: {
    title: 'AlarmLoLo',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AlarmLo: { title: 'AlarmLo', width: 150, readonly: false, hasMenu: true },
  AlarmHiHi: {
    title: 'AlarmHiHi',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AlarmHi: { title: 'AlarmHi', width: 150, readonly: false, hasMenu: true },
  DisplayFormat: {
    title: 'DisplayFormat',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  UnitOfMeasure: {
    title: 'UnitOfMeasure',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  LoggingFrequency: {
    title: 'LoggingFrequency',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  GroupName: {
    title: 'GroupName',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  IsActive: {
    title: 'IsActive',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  Description: {
    title: 'Description',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  AssetNumber: {
    title: 'AssetNumber',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  IsWriteable: {
    title: 'IsWriteable',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  Transform: {
    title: 'Transform',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  ScanFrequency: {
    title: 'ScanFrequency',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  DynamicProperties: {
    title: 'DynamicProperties',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  SourceType: {
    title: 'Source Type',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  AlarmType: {
    title: 'Alarm Type',
    width: 150,
    readonly: true,
    hasMenu: true,
  },
  ModbusRegister: {
    title: 'Modbus Register',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  DataType: {
    title: 'Data Type',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  ModbusIP: {
    title: 'Modbus IP Address',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  ModbusPort: {
    title: 'Modbus Port',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
  ModbusSlaveId: {
    title: 'Modbus Slave ID',
    width: 150,
    readonly: false,
    hasMenu: true,
  },
};

export const getLookupIDs = function (
  internalNameArray,
  lookupName,
  lookupValues,
) {
  let lookupIdArray = [];
  for (let h = 0; h < internalNameArray.length; h++) {
    for (let i = 0; i < lookupValues.length; i++) {
      let lk = lookupValues[i];
      if (lk.LookupName === lookupName) {
        for (let j = 0; j < lk.Values.length; j++) {
          if (lk.Values[j].InternalName === internalNameArray[h]) {
            lookupIdArray.push(lk.Values[j].LookupValueId);
          }
        }
      }
    }
  }
  return lookupIdArray;
};

export const getLookupValues = function (
  valueName,
  LookupValueId,
  lookupValues,
) {
  let lookupIdArray = [];
  let id = parseInt(LookupValueId);
  for (let i = 0; i < lookupValues.length; i++) {
    if (lookupValues[i].LookupName === valueName) {
      for (let j = 0; j < lookupValues[i].Values.length; j++) {
        if (lookupValues[i].Values[j].LookupValueId === id) {
          return lookupValues[i].Values[j].InternalName;
        }
      }
    }
  }
  return lookupIdArray;
};

export const lookupNameToId = function (value, category, lookupValues) {
  return getLookupIDs([value], category, lookupValues)[0];
};

export const lookupIdToName = function (id, category, lookupValues) {
  let name = getLookupValues(category, id, lookupValues);
  if (Array.isArray(name)) {
    return '';
  } else {
    return name;
  }
};

export const validSourceTypes = [
  'ModbusTCP',
  'ModbusRTU232',
  'ModbusRTU485',
  'CDL',
  'J1939',
  'Internal',
  'FunctionLibrary',
  'SystemMetric',
  'HWIO',
];

export const defaultDeviceParams = {
  ModbusTCP:
    '{"connection":{"ip":"0.0.0.0","port":"0","slave_id":"0"},"regnum":"0000"}',
  ModbusRTU232:
    '{"connection":{"port_number":"0","slave_id":"0"},"regnum":"0000"}',
  ModbusRTU485:
    '{"connection":{"port_number":"0","slave_id":"0"},"regnum":"0000"}',
  CDL: '{"pid":"1","mask":"","signed":false,"mid":""}',
};

export const isJsonString = (string) => {
  try {
    const parsed = JSON.parse(string);
    return parsed && typeof parsed === 'object' && !Array.isArray(parsed);
  } catch (e) {}
  return false;
};

export const updateDeviceParamsFormat = (
  deviceParams,
  sourceType,
  channelNumber,
) => {
  if (!isJsonString(deviceParams)) {
    if (sourceType.startsWith('ModbusRTU')) {
      const slash = deviceParams.indexOf('/');

      if (slash === -1) {
        // This should be present
        throw new Error('DeviceParams format not supported.');
      }

      const port = deviceParams.slice(0, slash);
      const slave_id = deviceParams.slice(slash + 1);

      const deviceParamsObj = {
        connection: {
          port_number: port,
          slave_id: slave_id,
        },
        regnum: channelNumber,
      };
      return JSON.stringify(deviceParamsObj);
    } else if (sourceType === 'ModbusTCP') {
      const colon = deviceParams.indexOf(':');
      const slash = deviceParams.indexOf('/');

      if (slash === -1 || colon === -1 || colon > slash) {
        // These should be present
        throw new Error('DeviceParams format not supported.');
      }

      const ip = deviceParams.slice(0, colon);
      const port = deviceParams.slice(colon + 1, slash);
      const slave_id = deviceParams.slice(slash + 1);

      const deviceParamsObj = {
        connection: {
          ip: ip,
          port: port,
          slave_id: slave_id,
        },
        regnum: channelNumber,
      };
      return JSON.stringify(deviceParamsObj);
    } else {
      return deviceParams;
    }
  } else {
    return deviceParams;
  }
};

export const tagToTagViewFormat = (gridTagObject) => {
  let assetTagView = {};

  // Main fields
  assetTagView.AssetTagId = parseInt(gridTagObject.AssetTagId) ?? 0;
  assetTagView.AssetId = parseInt(gridTagObject.AssetId);
  assetTagView.AssetNumber = gridTagObject.AssetNumber;
  assetTagView.TagIdentifier = parseInt(gridTagObject.TagIdentifier);
  assetTagView.MinValue = parseFloat(gridTagObject.MinValue);
  assetTagView.MaxValue = parseFloat(gridTagObject.MaxValue);
  assetTagView.AlarmLoLo = parseFloat(gridTagObject.AlarmLoLo);
  assetTagView.AlarmLo = parseFloat(gridTagObject.AlarmLo);
  assetTagView.AlarmHi = parseFloat(gridTagObject.AlarmHi);
  assetTagView.AlarmHiHi = parseFloat(gridTagObject.AlarmHiHi);
  assetTagView.LoggingFrequency = parseFloat(gridTagObject.LoggingFrequency);
  assetTagView.ChannelNumber = parseInt(gridTagObject.ChannelNumber);
  assetTagView.TagName = gridTagObject.TagName;
  assetTagView.TagTypeId = parseInt(gridTagObject.TagTypeId);
  assetTagView.GaugeTypeId = parseInt(gridTagObject.GaugeTypeId);
  assetTagView.DataTypeId = parseInt(gridTagObject.DataTypeId);
  assetTagView.SourceTypeId = parseInt(gridTagObject.SourceTypeId);
  assetTagView.Gain = parseFloat(gridTagObject.Gain);
  assetTagView.Offset = parseFloat(gridTagObject.Offset);
  assetTagView.DisplayFormat = gridTagObject.DisplayFormat;
  assetTagView.UnitOfMeasure = gridTagObject.UnitOfMeasure;
  assetTagView.ShortUnitOfMeasure = gridTagObject.ShortUnitOfMeasure;
  assetTagView.StatusAlarmLookupId = parseInt(
    gridTagObject.StatusAlarmLookupId,
  );
  assetTagView.DeviceParams = gridTagObject.DeviceParams;
  assetTagView.GroupName = gridTagObject.GroupName;
  assetTagView.SourceName = gridTagObject.SourceName;
  assetTagView.ShortDisplayName = gridTagObject.ShortDisplayName;
  assetTagView.Description = gridTagObject.Description;
  assetTagView.DefaultValue = parseFloat(gridTagObject.DefaultValue);
  assetTagView.IsDirty = true;
  assetTagView.SyncToHub = true;
  assetTagView.IsWriteable = gridTagObject.IsWriteable;
  assetTagView.IsActive = gridTagObject.IsActive;
  assetTagView.ShowInDisplay = gridTagObject.ShowInDisplay;
  assetTagView.Transform = gridTagObject.Transform;
  assetTagView.DynamicProperties = gridTagObject.DynamicProperties;
  assetTagView.ScanFrequency = parseFloat(gridTagObject.ScanFrequency);
  assetTagView.PortNumber = parseInt(gridTagObject.PortNumber);
  assetTagView.StrappingTableId = parseInt(gridTagObject.StrappingTableId);
  assetTagView.ServerGain = parseFloat(gridTagObject.ServerGain);
  assetTagView.ServerOffset = parseFloat(gridTagObject.ServerOffset);
  assetTagView.PerformScalingOnServer = gridTagObject.PerformScalingOnServer;
  assetTagView.Property = gridTagObject.Property;
  assetTagView.SparseData = gridTagObject.SparseData;
  assetTagView.DisplayAsDetail = gridTagObject.DisplayAsDetail;
  assetTagView.ParentAssetTagId = parseInt(gridTagObject.ParentAssetTagId);

  // Alarm fields
  assetTagView.Alarm2TypeId = parseInt(gridTagObject.Alarm2TypeId);
  assetTagView.AlarmTypeId = parseInt(gridTagObject.AlarmTypeId);
  assetTagView.AlarmId = gridTagObject.AlarmID;
  assetTagView.Alarm2Id = gridTagObject.Alarm2ID;
  assetTagView.HiBandNotificationPriorityId = parseInt(
    gridTagObject.HiBandNotificationPriorityId,
  );
  assetTagView.LoBandNotificationPriorityId = parseInt(
    gridTagObject.LoBandNotificationPriorityId,
  );
  assetTagView.AlarmArmingTypeId = parseInt(gridTagObject.AlarmArmingTypeId);
  assetTagView.AlarmArmingTypeId = parseInt(gridTagObject.AlarmArmingTypeId);
  assetTagView.IsAlarmEnabled = gridTagObject.IsAlarmEnabled;
  assetTagView.Alarm2IsEnabled = gridTagObject.Alarm2IsEnabled;
  assetTagView.AlarmArmingDelay = parseFloat(gridTagObject.AlarmArmingDelay);
  assetTagView.Alarm2ArmingTimeLimit = parseFloat(
    gridTagObject.Alarm2ArmingTimeLimit,
  );
  assetTagView.AlarmDeadband = parseFloat(gridTagObject.AlarmDeadband);
  assetTagView.Alarm2Deadband = parseFloat(gridTagObject.Alarm2Deadband);
  assetTagView.AlarmTimeLimit = parseFloat(gridTagObject.AlarmTimeLimit);
  assetTagView.Alarm2TimeLimit = parseFloat(gridTagObject.Alarm2TimeLimit);
  assetTagView.AlarmSeverityId = parseInt(gridTagObject.AlarmSeverityId);
  assetTagView.Alarm2PriorityId = parseInt(gridTagObject.Alarm2PriorityId);
  assetTagView.AlarmLimitRangeId = parseInt(gridTagObject.AlarmLimitRangeId);
  assetTagView.Alarm2LimitRangeId = parseInt(gridTagObject.Alarm2LimitRangeId);
  assetTagView.AlarmRateLimit = parseFloat(gridTagObject.AlarmRateLimit);
  assetTagView.Alarm2RateLimit = parseFloat(gridTagObject.Alarm2RateLimit);
  assetTagView.AlarmDigitalOutputChannel = parseInt(
    gridTagObject.AlarmDigitalOutputChannel,
  );

  // Delete values of NaN and empty strings
  for (const [key, value] of Object.entries(assetTagView)) {
    if (Number.isNaN(value) || value === '') {
      delete assetTagView[key];
    }
  }

  return assetTagView;
};

export const tagToTagLayerViewFormat = (gridTagObject) => {
  let tagLayerView = tagToTagViewFormat(gridTagObject);
  delete tagLayerView.AssetTagId;
  tagLayerView.TCTagLayerId = gridTagObject.TCTagLayerId ?? 0;
  return tagLayerView;
};
